import startCase from 'lodash/startCase'

const { protocol_id } = useFilters()


/**
 * APP MENU
 * {
 *   `entity`  used to check if current user has access to this
 *   `to`      routing path (default to /entity)
 *   `icon`    icon to shown in menu
 *   `text`    label to show in menu (default: ucfirst(entity))
 * }
 */
const menu = [
  {
    entity: 'dashboard',
    to: '/dashboard',
    icon: 'mdi-view-dashboard',
    text: 'Dashboard'
  },
  {
    entity: 'explorer',
    to: computed( () => protocol_id.value ? `/explorer/${protocol_id.value}_` : '/explorer'),
    icon: 'mdi-view-column-outline',
    text: 'Data Explorer'
  },
  // {
  //   entity: 'task',
  //   to: '/task',
  //   icon: 'mdi-checkbox-marked-circle-plus-outline',
  //   text: 'Task'
  // },
  // {
  //   entity: 'report',
  //   icon: 'mdi-chart-bar',
  //   text: 'Report',
  //   to: '/report'
  // },
  {
    entity: 'pending',
    icon: 'mdi-timer-sand',
    text: 'Pending',
    to: '/pending'
  },  
  {
    entity: 'milestone',
    icon: 'mdi-timeline-check-outline',
    text: 'Milestone',
    links: [
      {
        entity: 'milestone',
        to: '/task',
        icon: 'mdi-checkbox-marked-circle-plus-outline',
        text: 'List',
        name: 'Milestone',
        label: 'Milestone'
      },
      {
        entity: 'milestone',
        action: 'setup',
        icon: 'mdi-timeline-check-outline',
        text: 'Set-Up',
        to: '/milestone_setup',
      },      
      {
        entity: 'milestone',
        action: 'report',
        icon: 'mdi-chart-gantt',
        text: 'Report',
        to: '/report'
      },
    ]
  },
  {
    entity: 'metric',
    icon: 'mdi-chart-bar',
    text: 'Metrics',
    to: '/metric'
  },  
  {
    text: 'Protocol Settings',
    entity: 'settings',
    icon: 'mdi-cogs',
    links: [
      {
        entity: 'protocol_has_organization',
        icon: 'mdi-clipboard-list',
        text: 'Protocol Organizations',
        to: '/protocol_has_organization',
      },
      {
          entity: 'protocol_has_personnel',
          icon: 'mdi-card-account-details-outline',
          text: 'Protocol Personnel',
          to: '/protocol_has_personnel',
      },
      {
          entity: 'treatment',
          icon: 'mdi-pill',
          text: 'Protocol IP',
          to: '/ip',
      },
      {
        entity: 'protocol_profile',
        text: 'Protocol Profiles',
        to: '/profile',
        icon: 'mdi-account-hard-hat',
      },
      {
        entity: 'protocol_has_account',
        icon: 'mdi-account-box-plus-outline',
        text: 'Protocol Accounts',
        to: '/protocol_has_account',
      },
      {
        entity: 'acl_permission',
        icon: 'mdi-account-cog',
        text: 'Protocol Permissions',
        to: '/permission',
      },
      {
        entity: 'data_dictionary',
        to: '/dd',
        icon: 'mdi-database-cog',
        text: 'Data Dictionary'
      },
      {
        entity: 'tree_permission',
        to: '/tree_permission',
        icon: 'mdi-forest-outline',
        text: 'Tree Permission'
      },
    ]
  }
]

export default menu
  .map(item => ({
    type: item.type || 'core-drawer-link',
    entity: item.entity,
    action: item.action || 'view',
    to: item.to || `/${item.entity}`,
    icon: item.icon,
    text: item.text || startCase(item.entity),
    links: item.links ?
      item.links.map(l => ({
        entity: l.entity,
        action: l.action || 'view',
        to: l.to || `/${l.entity}`,
        icon: l.icon,
        text: l.text || startCase(l.entity),      
      })) : false
}))