<script setup>
const { $on, $emit } = useNuxtApp()
const type = ref('confirmDialog') // prompt
$on('confirmDialog', confirmDialog)

$on('promptDialog', promptDialog)

let msg = ref('')
let title = ref('')
let visible = ref(false)
const userInput = ref('')
const name = ref('')

function confirmDialog (ev) {
  type.value = 'confirmDialog'
  msg.value = ev.msg
  title.value = ev.title || 'Confirm'
  visible.value = true
}

function promptDialog (ev) {
  type.value = 'promptDialog'
  msg.value = ev.msg
  title.value = ev.title
  name.value = ev.name
  visible.value = true
}

function result (value) {
  visible.value = false
  if (type.value === 'confirmDialog') {
    $emit('confirmDialog:result', value)
  } else {
    $emit('promptDialog:result', value && userInput.value )
    userInput.value = ''
  }
}

</script>
<template>
  <v-dialog v-model='visible' width="400px">
    <CoreCard color="warning" icon='$warning' :title="title" @confirm="result(true)" @cancel="result(false)" show-cancel>
        <div v-html='msg' />
          <v-textarea v-if='type === "promptDialog"' :label='name' v-model='userInput' />
          <template v-if='type === "promptDialog"' v-slot:actions>
              <v-btn color='warning' @click="result(false)" icon="mdi-close" class="confirm-btn-cancel"></v-btn>
              <v-btn :disabled='!userInput.length' color='success' @click="result(userInput)" text label icon="mdi-check" class="confirm-btn-ok"></v-btn>
          </template>
    </CoreCard>
        <!-- <v-btn class='mr-1' @click='result(false)' color='error'>Cancel</v-btn>
        <v-btn @click='result(true)' color='success'>Ok</v-btn> -->
  </v-dialog>
</template>
