
export default [ 
    {
        entity: 'account',
        to: '/accounts',
        icon: 'mdi-account',
        text: 'Accounts'
    },


    {
        entity: 'protocol',
        icon: 'mdi-clipboard-text',
        text: 'Protocols',
        to: '/protocols',
    },

    // {
    //     entity: 'profile',
    //     to: '/profile',
    //     // icon: 'mdi-face-man-profile',
    //     icon: 'mdi-account-hard-hat',
    //     text: 'Protocols Profiles'
    // },
    // {
    //     entity: 'protocol_has_account',
    //     icon: 'mdi-account-box-plus-outline',
    //     text: 'Protocols Accounts',
    //     to: '/protocol_has_account',
    // },

    // {
    //     entity: 'protocol_has_account_has_organization',
    //     icon: 'mdi-card-account-details',
    //     text: 'Accounts Organizations',
    //     to: '/protocol_has_account_has_organization',
    // },

    // {
    //     entity: 'permission',
    //     to: '/permission',
    //     icon: 'mdi-account-cog',
    //     text: 'Permission'
    // },


    // {
    //     entity: 'tree_permission',
    //     to: '/tree_permission',
    //     icon: 'mdi-forest-outline',
    //     text: 'Tree Permission'
    // },
    
    // {
    //     entity: 'study_role',
    //     to: '/study_role',
    //     icon: 'mdi-account-hard-hat',
    //     text: 'Study Roles'
    // },
    
    {
        entity: 'organization',
        icon: 'mdi-hospital-building',
        to: '/organizations',
        text: 'Organizations'
    },

    // {
    //     entity: 'protocol_has_organization',
    //     icon: 'mdi-clipboard-list',
    //     text: 'Protocols Organizations',
    //     to: '/protocol_has_organization',
    // },
    

    {
        entity: 'personnel',
        to: '/personnel',
        icon: 'mdi-users',
        text: 'Personnel'
    },
    
 
    // {
    //     entity: 'protocol_has_personnel',
    //     icon: 'mdi-card-account-details-outline',
    //     text: 'Protocols Personnel',
    //     to: '/protocol_has_personnel',
    // },
    // {
    //     entity: 'treatment',
    //     icon: 'mdi-pill',
    //     text: 'Protocol IP',
    //     to: '/ip',
    // },
    
    // {
    //   entity: 'example',
    //   icon: 'mdi-thumb-up',
    //   text: 'Example',
    //   to: '/example',
    // },
    
    // {
    //     entity: 'data_dictionary',
    //     to: '/admin/dd',
    //     icon: 'mdi-database-cog',
    //     text: 'Data Dictionary'
    // },
    
    // {
    //     entity: 'task',
    //     to: '/task',
    //     icon: 'mdi-checkbox-marked-circle-plus-outline',
    //     text: 'Task'
    // },
    
]
