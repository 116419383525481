<script setup>
import { debounce } from 'lodash'
const router = useRouter()

const { protocol_id, filters, hasFilters } = useFilters()
const { modelValue, navbar } = defineProps({
    modelValue: Object,
    navbar: Boolean,
    id: { type: String, default: 'globalSearchDocument'}
})

const data = reactive({
    search: '',
    document_schema: '',
    documents: [],
    pending: false,
    focus: false
})

async function _refresh (search) {
    if (!data.focus) return
    data.pending = true
    data.documents = await useAPI('filesystem/search', { filters: { search: `%${search}%`, protocol_id: protocol_id.value } })
    data.pending = false
}
_refresh()

const refresh = debounce(_refresh, 200)

const emit = defineEmits(['update:modelValue'])

function selected (item) {
    emit('update:modelValue', item)
    if (navbar) {
        router.push(`/explorer/${protocol_id.value}_${item?.path_code}`)
    }
}


watch(filters.value, () => {
    if (hasFilters.value) {
        emit('update:modelValue', '')
        _refresh()
    }
})


</script>
<template>
    <div>
        <v-autocomplete
            :menu-props="{ width: '500px' }"
            clearable
            :loading='data.pending'
            auto-select-first
            prepend-inner-icon="mdi-document"
            :hide-details="navbar"
            density="compact"
            :clearable="navbar"
            :counter-value="null"
            label='Search for a document'
            :items="data.documents ?? []"
            item-title="name"
            item-value="path_code"
            :hint="modelValue?.parentPath"
            persistent-hint
            no-filter
            return-object
            :rules="!navbar ? [$valid.required] : []"
            v-model:search="data.search"
            :model-value="modelValue"
            v-model:focused="data.focus"
            @update:search="refresh"
            @update:model-value="selected"
            autocomplete="off"
            :id="id"
            >
            <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props">
                    <template #title>{{ item?.raw?.name }}<v-chip class="float-right" density="compact" label variant="tonal" color="green">{{ item?.raw?.human_code }}</v-chip> </template>
                    <template #subtitle>{{ item?.raw?.parent_path_name }}</template>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>