<script setup>

const { modelValue } = defineProps({ modelValue: null })
const emit = defineEmits(['update:modelValue'])

const { setProtocol, setRootFolder, protocol_id } = useFilters()
const { getList } = useBaseEntity()
const { can } = useAuth()

import links from './menu'

const allowedLinks = computed(() => {
  if (!protocol_id?.value) {
    // only return dashboard when no protocol is selected
    return [links[0]]
  }

  return links 
    .filter(l => can(l.action, l.entity)) // remove unallowed links
    .map(l => {
      if (l?.links) {
        return {...l, links: l.links.filter(l => can(l.action, l.entity)) }
      }
      return l
    }) // remove internal unallowed links
    .filter(l => l.links?.length ?? 1 !== 0 )
})


const protocolList = await getList('protocol', undefined, { rowsPerPage: -1 }).catch(e => { return []})

async function updateProtocol (protocol) {
  if (!protocol) {
    setProtocol(null)
    return
  }

  setProtocol(protocol)
}

</script>
<template>
  <v-navigation-drawer :model-value="modelValue" @update:modelValue="e => emit('update:modelValue', e)" persistent app
    mobile-breakpoint="991" width="290" v-if="protocolList?.length">
    <v-list density="compact" class="mt-0 pt-0">

      <v-list-item class='pa-3 bg-primary' color="primary">

        <v-list-item-title class="title text-right">
          <div class="etmf"><strong>eTMF</strong></div>
          <v-toolbar-title class="text-left">
            <v-img width="110" src='/tsh_logo.png' contain />

          </v-toolbar-title>
        </v-list-item-title>
      </v-list-item>

      <v-divider />
      
      <v-list-item class='pl-2 pr-2 pb-0 pt-2 bg-secondary' color="primary">
        <CoreField color="bg-primary" :model-value="protocol_id" @update:selected="v => updateProtocol(v)" select
          :field="{ key: 'global_protocol_id', list: protocolList, type: 'list', label: 'Protocol', subtitle: 'sponsor.name', size: 12 }" />
      </v-list-item>

      <v-divider />

      <template v-for="(link, i) in allowedLinks" :key="i">
        <v-list-group  :id="`menu_activator_${link.entity}`" :key='link.entity' class='pa-0 ma-0' v-if="link?.links.length" no-action :prepend-icon="link?.icon">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" :title="link.text" :prepend-icon="link?.icon" />
          </template>
          <v-list-item :id="`menu_${link.entity}_${link.action}`" class='pa-0 ma-0' v-for="(link, id) in link.links" :key="id" :title="link.text" :to="link.to" :prepend-icon="link?.icon" />
        </v-list-group>
        <v-list-item :id="`menu_${link.entity}_${link.action}`" v-else :title="link.text" :to="link.to" link exact :prepend-icon="link?.icon" />
      </template>
    </v-list>

  </v-navigation-drawer>
</template>

<style>
.etmf {
  width: 100px;
  float: right;
  font-style: italic;
  /* margin-bottom: 0px; */
  position: relative;
  height: 40px;
  /* background: red; */
  line-height: 61px;
  font-size: 1.5em;
  /* opacity: 0.6; */
  color: #2DA4D3;
}
</style>